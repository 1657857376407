html {
    font-size: calc(100vw / 19.2);
}

#app {
    font-size: initial;
}

.App {
    margin: 0;
    padding: 0;
    overflow: auto;
    background-image: url("./assets/banner.webp");
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.left {
    position: relative;
    width: 8.6rem;
    box-sizing: border-box;
    padding: 20px;
    margin: 5vw 0;
    margin-left: 4.6vw;
    color: #fff;
    /* background:  blur(67.9570465088px); */
    border-radius: 30px;
    /* background: rgba(20, 58, 97, 0.30);
    backdrop-filter: blur(67.95704650878906px); */
    fill: #A71767;
    background:#A71767;
    backdrop-filter: blur(67.5px);
}

.content{
  margin-left: 0.2rem;
  position: relative;
  z-index: 1; 
  padding: 21px; 
}

.title {
    text-align: center;
    color: #FFF;
    font-family: Helvetica;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.secTitle {
    color: #FFF;
    font-family: Helvetica;
    font-size: 0.55rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 6px;
}

.right {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
}

.right img {
    width: 1rem;
    height: 1rem;
}

.download {
    color: #FFF;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
}

.chose {
    color: #FFF;
    font-family: Helvetica;
    font-size: 0.45rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 36px;
}

.address {
    color: #FFF;
    font-family: Helvetica;
    font-size: 0.22rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.appstore {
    width: 100%;
    height: 0.95rem;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    text-align: center;
    margin: 30px 0 ;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appstore .img {
    height: 100%;
}

.carousel {
    width: 60vw;
}

.banner {
    width: 60vw;
    height: 100vh;
    background-size: cover;
    background-position: right center;
}

.download img {
    width: 2.3rem;
    flex: 1;
    margin: 5px;
}

.download p {
    /* width: 2.3rem;  */
    /* height: 0.66rem;  */
    font-size: 0.25rem;
    /* flex: 1; */
    /* margin: "5px"; */
}

.option {
    margin-top: 0.2rem;
    display: flex;
    /* gap: 0.3rem; */
    justify-content: space-between;

}

a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
    color: #fff;
}

.option_item {
    display: flex;
    align-items: center;
    gap: 0.04rem;
    cursor: pointer;
}

.option img {
    width: 0.47rem;
    /* height: rem; */
}
@media (max-width: 400px) {
    .left{
      width: 100%;
      margin: 0;
      overflow: auto;
    }
    .content{
        margin-left: 0;
        padding:0
    }
  }
@media screen and (max-width: 640px) {
    html {
        font-size: calc(100vw / 7.5);
    }

    .carousel {
        display: none !important;
    }

    .bg {
        width: 100vw !important;
        background-position: center;
    }

    .context {
        backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, 0.3);
    }

    .download img {
        width: 41%;
        height: auto;
    }

    .logo {
        width: 0.88rem;
        height: 0.88rem;
    }
}

.google_login {
    border: 2px solid #fff;
    width: 500px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 8px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    z-index: 2;
}

.google_login::before {
    content: "";
    background-image: linear-gradient(315deg, #ffffff 0%, rgba(255, 255, 255, 0.12) 100%);
    position: absolute;
    backdrop-filter: blur(9px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.g_id_signin {
    width: 100%;
}

.page_body {
    width: 100vw;
    height: 90vh;
    padding-top: 10vh;
    position: relative;
    overflow: hidden;

    background-image: url("./assets/banner.webp");
    background-size: cover;
}

.page_body::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
